<template>
  <div class="page">
    <div class="container">
      <div class="column spacer">
        <span class="breadcrumbs"><a href="/">Home</a> » Error 404: pagina niet gevonden</span>
        <h1>De pagina waarnaar u zocht, is niet gevonden</h1>
        <p>Het kan natuurlijk zijn dat u hier terecht bent gekomen via een verouderde link. In dat geval, zoekt u hieronder naar de juiste inhoud of raadpleeg de domeinnaam checker, indien u op zoek bent naar de beschikbaarheid van een domeinnaam binnen de ruim 1140 domeinnaam-extensies (TLD's) die wij aanbieden.</p>
        <h2>Zoek naar domeinnamen:</h2>
        <CheckDomainInput/>
      </div>
    </div>
  </div>
</template>

<script>
import CheckDomainInput from '@/components/CheckDomainInput.vue';

export default {
  name: 'PageNotFound',
  components: {
    CheckDomainInput,
  },
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  font-size: 13px;
  margin-bottom: 10px;
}
</style>